
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import userApi from "@/core/services/User";
import { useI18n } from "vue-i18n";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "administration-users",
  components: {},
  data() {
    return {
      name: "",
      users: [],
      filter: {
        identity: "",
        email: "",
        user_type: "",
        status: "",
      },
      loading: true,
    };
  },
  methods: {
    resendUserInvitation() {
      userApi.resendUserInvitation().then((response) => {
        Swal.fire({
          text: response.data.user_count + " utilisateurs ont de nouveau reçu leur invitation",
          icon: "success",
          buttonsStyling: false,
          confirmButtonText: "Fermer",
          customClass: {
            confirmButton: "btn fw-bold btn-light-success",
          },
        });
      });
    },
    resetFilter() {
      this.filter = {
        identity: "",
        email: "",
        user_type: "",
        status: "",
      };
      this.fetchUsers();
    },
    fetchUsers() {
      this.loading = true;
      userApi
        .getUsers(this.filter)
        .then((response) => {
          this.users = response.data["users"];
          this.loading = false;
        })
        .catch(() => {
          this.displayErrorMsg("Une erreur est survenue lors de la récupération des utilisateurs.");
        });
    },
    displayErrorMsg(msg: string) {
      Swal.fire({
        text: msg,
        icon: "error",
        buttonsStyling: false,
        confirmButtonText: "Fermer",
        customClass: {
          confirmButton: "btn fw-bold btn-light-danger",
        },
      });
    },
  },
  setup() {
    const { t } = useI18n();
    const router = useRouter();
    setCurrentPageBreadcrumbs(t("pages.dashboard.users"), []);

    function editUser(user: any) {
      userApi.getSpecificUser(user.id).then((response) => {
        router.push({ name: "usersdetail", params: { user_id: user.id }, query: { admin: "true" } });
      });
    }

    return { t, editUser };
  },
  watch: {
    "$i18n.locale": function () {
      setCurrentPageBreadcrumbs(this.t("pages.dashboard.users"), []);
    },
  },
  created() {
    this.fetchUsers();
  },
});
