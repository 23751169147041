import ApiService from "@/core/services/ApiService";

const GET_USERS = "auser/get-users/";
const ADD_USERS = "auser/add-users/";
const RESEND_USER_INVITATION = "auser/resend-user-invitation";
const GET_SPECIFIC_USER = "auser/user/<pk>/";
const GET_SPECIFIC_USERPROFILE = "userprofile/get-userprofile/<pk>";
const UPDATE_USERPROFILE = "userprofile/update-userprofile/<pk>/";

export default {
    resendUserInvitation() {
        ApiService.setHeader();
        return ApiService.get(RESEND_USER_INVITATION).then((response) => {
            return response.data;
        });
    },
    getUsers(filters) {
        ApiService.setHeader();
        return ApiService.post(GET_USERS, filters).then((response) => {
            return response.data;
        });
    },
    getSpecificUser(userId) {
        ApiService.setHeader();
        return ApiService.get(GET_SPECIFIC_USER.replace("<pk>", userId)).then((response) => {
            return response.data;
        });
    },
    getSpecificUserProfile(userprofile_id) {
        ApiService.setHeader();
        return ApiService.get(GET_SPECIFIC_USERPROFILE.replace("<pk>", userprofile_id)).then((response) => {
            return response.data;
        });
    },
    addUsers(users) {
        ApiService.setHeader();
        return ApiService.post(ADD_USERS, users).then((response) => {
            return response.data;
        });
    },
    updateUserProfile(userprofile_id, payload) {
        ApiService.setHeader();
        return ApiService.put(UPDATE_USERPROFILE.replace("<pk>", userprofile_id), payload).then((response) => {
            return response.data;
        });
    }
};
